.it-nav-wrapper {
  .it-header-center-wrapper {
    .it-header-center-content-wrapper {
      .it-brand-wrapper {
        a {
          .icon {
            width: auto;
          }
        }
      }
    }
    .it-brand-text {
      &,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $black;
      }
    }
    .it-right-zone {
      .it-socials {
        ul {
          a {
            .icon {
              color: $primary;
              fill: $primary;
            }

            &:hover .icon {
              color: shade-color($primary, 30%);
              fill: shade-color($primary, 30%);
            }
          }
        }
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    .it-header-navbar-wrapper {
      background-color: $primary;
    }
    .it-right-zone {
      .it-search-wrapper {
        a.search-link {
          background-color: $primary;
        }
      }
    }
  }
  @media (max-width: #{map-get($grid-breakpoints, lg)}) {
    .it-right-zone {
      .it-search-wrapper {
        a.search-link svg {
          fill: $primary;
        }
      }
    }
  }
  .custom-navbar-toggler svg {
    fill: $primary;
  }
}
