//site
$primary-h: 0 !default;
$primary-s: 81.5 !default;
$primary-b: 82.7 !default;

$primary: #d32727;

$dark-gray-bg: rgb(48, 48, 48);

//$secondary;
//$tertiary: #fed505;
//$tertiary-text: $white;

$header-slim-bg-color: $dark-gray-bg;
//$header-bg-color: $monet-blue;
$header-center-bg-color: #fff;
$header-center-text-color: #1b1c1d;

//$headings-color: $primary;
//$semibold: 600;

// GDRP-BANNER RELATED
// $gdpr-accept-all: #005700 !default;
// $gdpr-toggle-checked: #005700 !default;
// $gdpr-toggle: #b22515 !default;
// $gdpr-toggle-border: #000 !default;
// $gdpr-focus-color: #ff9800 !default;
// $gdpr-link-color: #004285 !default;
// $gdpr-focus-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
//   0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem $gdpr-focus-color !default;
